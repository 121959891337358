import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from './AuthService';
import './ResetPassword.css'; // Import your CSS file
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ResetPassword = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const resetPassword = async () => {
    if (newPassword !== confirmNewPassword) {
      toast.error("Passwords Do Not Match", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      return;
    }

    try {
      const response = await AuthService.resetPassword(newPassword, token);
      console.log("Reset Password Response:", response); // Log the response

      if (response.ok) {
        navigate('/'); // Use navigate function to redirect to login page
        toast.success("Password reset successful", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
         // Display a confirmation message to the user
         alert('Password reset successful.');
      } else {
        const data = await response.json();
        toast.error("Error resetting password", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      toast.error('Error resetting password. Please try again later.');
    }
  };

  return (
    <div className="reset-password-container">
      <h1>Reset Password</h1>
      <input
        type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <input
        type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
        placeholder="Confirm New Password"
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />
      <button className="toggle-password-button" onClick={togglePasswordVisibility}>
        {showPassword ? "Hide Password" : "Show Password"}
      </button>
      <button className="reset-password-button" onClick={resetPassword}>Reset Password</button>
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default ResetPassword;
