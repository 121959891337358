import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Privacy from './Privacy';
import Deletion from './Deletion';
import ResetPassword from './ResetPassword'; // Import the ResetPassword component

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/deletion" element={<Deletion />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} /> {/* Add this route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
