import React from 'react';
import './Privacy.css';  // Import your CSS file

const Privacy = () => {
    return (
        <div className="privacy-container">
             <section className="privacy-section">
            <h1 className="privacy-heading">Shalom Privacy Policy</h1>

            <p className="privacy-intro">
                Shalom is committed to protecting your privacy and ensuring the security of your personal information. This privacy policy outlines how we collect, use, and safeguard your data. By using our app, you agree to the terms outlined in this policy.
            </p>

            <h2 className="privacy-section">Data Collection</h2>
            <p className="privacy-paragraph">
                We collect the following types of data during registration:
            </p>
            <ul className="privacy-list">
                <li><strong>Name:</strong> Your full name.</li>
                <li><strong>Email:</strong> Your email address.</li>
                <li><strong>Phone Number:</strong> Your contact number.</li>
                <li><strong>Location:</strong> Your location information.</li>
                <li><strong>Password:</strong> An encrypted version of your chosen password.</li>
            </ul>

            <h2>Data Usage</h2>
            <p>
                We use the collected data for the following purposes:
            </p>
            <ul>
                <li><strong>Account Creation:</strong> To create and manage your user account for accessing our services.</li>
                <li><strong>Order Processing:</strong> If you order a househelp, we share your phone number with a bureau for service fulfillment purposes only.</li>
                <li><strong>Security Measures:</strong> Passwords are encrypted for enhanced security.</li>
                <li><strong>Service Improvement:</strong> Analyzing usage patterns to enhance and improve our app's features.</li>
            </ul>

            <h2>Data Sharing</h2>
            <p>
                We do not share your data with third parties for any purpose other than fulfilling househelp orders. Your phone number is shared with the bureau responsible for providing the househelp service. We ensure that all service providers are contractually obligated to protect your data and use it solely for the intended purpose.
            </p>

            <h2>Data Protection</h2>
            <p>
                We implement technical and organizational measures to protect your data:
            </p>
            <ul>
                <li><strong>Encryption:</strong> Passwords are stored and transmitted in encrypted form.</li>
                <li><strong>Limited Access:</strong> Only authorized personnel have access to your data.</li>
            </ul>

            <h2>Data Retention</h2>
            <p>
                We retain your data for as long as you have an active account on Shalom. You can delete your data at any time using the provided options in the app settings. Upon deletion, we will erase your data from our systems and backups within a reasonable timeframe.
            </p>

            <h2>User Rights</h2>
            <p>
                You have the following rights regarding your data:
            </p>
            <ul>
                <li><strong>Access:</strong> View and access your data within the app.</li>
                <li><strong>Correction:</strong> Edit and update your data within the app.</li>
                <li><strong>Deletion:</strong> Delete your data using the app settings.</li>
                <li><strong>Complaints:</strong> Contact us with any concerns or lodge a complaint with the relevant supervisory authority.</li>
            </ul>

            <h2>Policy Updates</h2>
            <p>
                We may update this policy to reflect changes in our services or regulations. We will notify you of any material changes through the app or via email. Continued use of our app after the notice period constitutes acceptance of the updated policy.
            </p>

            <h2 className="privacy-section">Contact Us</h2>
            <p className="privacy-paragraph">
                If you have questions or feedback regarding this policy or our privacy practices, please contact us at <a href="mailto:shalomke98@gmail.com" className="privacy-contact-email">shalomke98@gmail.com</a>.
            </p>
            </section>

           
            <section className="terms-section">
                <h1 className="privacy-heading">Shalom Terms and Conditions</h1>

                <section className="terms-card">
                    <h2 className="terms-card-title">How to use the app</h2>
                    <div className="terms-card-content">
                        <p>1. Register for an account or log in if you already have one.</p>
                        <p>2. Browse the available bureaus and househelps.</p>
                        <p>3. Add your favorite househelps to your favorites list for easy access.</p>
                        <p>4. Contact the bureau to hire a househelp and settle on the terms.</p>
                        <p>5. Make payment by clicking the "Make payment" button on the Househelp's profile page and choose your method of payment. If you select Mobile Money (M-PESA) payment, a push notification will be sent to your phone number to complete the payment process.</p>
                        <p>6. Await the delivery of the househelp, and confirm receipt of the househelp before funds are disbursed to the bureau. Should a househelp not get delivered within 24 hours of making an order your funds will be refunded.</p>
                        <p>7. Manage your account settings and personal information in the Profile and Account Settings sections.</p>
                    </div>
                </section>

                <section className="terms-card">
                    <h2 className="terms-card-title">Terms and Conditions</h2>
                    <div className="terms-card-content">
                        <p>1. Users must be 18 years or older to create an account.</p>
                        <p>2. Users are responsible for maintaining the confidentiality of their account information.</p>
                        <p>3. The app is not responsible for any disputes arising between users and bureaus.</p>
                        <p>4. Users must adhere to all applicable laws and regulations when using the app.</p>
                        <p>5. The app reserves the right to modify or discontinue any features or services at any time without notice.</p>
                        <p>6. Commission: The client agrees to pay the Bureau a one-time commission equal to half the monthly salary of the house help.</p>
                        <p>7. App Commission: The Bureau agrees to pay the app a commission equal to 30% of the commission paid to the Bureau by the client.</p>
                        <p>8. Usage and Conduct: Users of this app must abide by all applicable laws and regulations and must not engage in any activities that may cause harm, offense, or inconvenience to others. The app reserves the right to suspend or terminate user accounts if they are found to be in violation of these terms and conditions.</p>
                    </div>
                </section>
            </section>

            <section className="delete-account-section">
                <h2 className="privacy-heading">Delete Your Account</h2>
                <p>
                    If you wish to delete your account and associated data, please use the following link:
                    {' '}
                    <a href="https://shalomdomestics.co.ke/deletion" className="delete-account-link">
                        Delete My Account
                    </a>
                </p>
                <p>
                    Please note that by clicking the link, you will be directed to a page where you can submit a request for account deletion. We will process your request and delete your account and data according to our privacy policy.
                </p>
            </section>

            <section className="thank-you">
                Thank you for using Shalom!
            </section>

        </div>
    );
};

export default Privacy;

