// AuthService.js

const AuthService = {
    resetPassword: async (newPassword, token) => {
      try {
        const response = await fetch(`https://shalomke-577d76508e09.herokuapp.com/api/users/reset/${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ password: newPassword }),
        });
  
        return response;
      } catch (error) {
        console.error('An unexpected error occurred:', error);
        throw error;
      }
    },
    // Add other authentication-related methods as needed
  };
  
  export default AuthService;
  