import React, { useState } from 'react';
import './Deletion.css'; // Import your CSS file

const Deletion = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        userId: '',
        reason: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Send a request to your backend endpoint
            const response = await fetch('https://shalomke-577d76508e09.herokuapp.com/api/users/delete-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            if (response.ok) {
                // Request successful
                console.log('Deletion request submitted:', formData);
    
                // Display a confirmation message to the user
                alert('Deletion request submitted successfully. You will receive an email shortly.');
    
                // Optionally, redirect the user or perform other actions
            } else {
                // Handle errors
                console.error('Error submitting deletion request:', response.statusText);
                alert('Error submitting deletion request. Please try again later.');
            }
        } catch (error) {
            console.error('An unexpected error occurred:', error);
            alert('An unexpected error occurred. Please try again later.');
        }
    };
    

    return (
        <div className="deletion-container">
            <h1 className="deletion-heading">Shalom Account Deletion Request</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Full Name:
                    <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
                </label>
                <label>
                    Email:
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </label>
                <label>
                    User Phone Number:
                    <input type="text" name="userId" value={formData.userId} onChange={handleChange} />
                </label>
                <label>
                    Reason for Deletion:
                    <textarea name="reason" value={formData.reason} onChange={handleChange} required />
                </label>
                <label>
                    <input type="checkbox" required />
                    I understand that deleting my account is irreversible and will result in the loss of all my data.
                </label>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default Deletion;
