import React from 'react';
import './Home.css'; // Import your CSS file
import promoImage from './assets/images/promoImage.png'; // Adjust the path as necessary


const Home = () => {
    return (
        <div className="home-container">
            <h1 className="home-heading">Welcome to Shalom</h1>
            <p className="home-description">Discover the easiest way to find reliable househelp services with Shalom.</p>

            <h2 className="advantages-heading">Why Shalom?</h2>
            <ul className="advantages-list">
                <li>Effortless Househelp Hiring: Find and hire experienced househelps with just a few clicks.</li>
                <li>Secure and Reliable: Shalom ensures the security of your information and provides a reliable platform for service.</li>
                <li>Transparent Process: Our transparent process ensures clarity in hiring and payments.</li>
                <li>Customer Support: Our dedicated customer support team is ready to assist you at every step.</li>
            </ul>

            <h2 className="how-it-works-heading">How It Works</h2>
            <ol className="how-it-works-list">
                <li>Create an Account: Sign up for a Shalom account to get started.</li>
                <li>Browse and Select: Explore a variety of househelps and bureaus, choose the ones that fit your requirements.</li>
                <li>Order and Confirm: Place an order for the selected househelp.</li>
                <li>Secure Payment: Make a secure payment through our platform using MPESA Prompt Payment.</li>
                <li>Receive Househelp: Await the arrival of the househelp and confirm receipt upon delivery. NB: Funds are disbursed to the Bureau once House Help delivery has been confirmed</li>
            </ol>

            {/* <img src={promoImage} alt="Promotion" className="promo-image"/> */}
            <div className="download-section">
    <a href="https://play.google.com/store/apps/details?id=io.ionic.shalom" target="_blank" rel="noopener noreferrer" className="download-button">
        Download Shalom on Google Play
    </a>
</div>

            <p className="home-footer">Experience the convenience of Shalom – your trusted partner in househelp services.</p>
        </div>
    );
};

export default Home;
